var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          !_vm.showForecast
            ? [
                _c(
                  "div",
                  { staticClass: "tag" },
                  _vm._l(_vm.config.billStatusOptions, function (item) {
                    return _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "permissson",
                            rawName: "v-permissson",
                            value: `${item.permissson}`,
                            expression: "`${item.permissson}`",
                          },
                        ],
                        key: item.value,
                        class: [
                          "tag-item",
                          { "tag-acitve": _vm.activeName === item.value },
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.handleClick("tag1", item.value)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            item.label +
                              (item.value !== ""
                                ? `(${
                                    _vm.tabNumer?.find(
                                      (a) => a.type === +item.value
                                    )?.qty || 0
                                  })`
                                : `(${_vm.allTotal})`)
                          ) + " "
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "opt" }, [
                  _vm.filterList.date
                    ? _c("div", { staticClass: "checkDate flex_c_c" }, [
                        _vm._v(
                          " 查询时间：" +
                            _vm._s(
                              _vm.filterList.date[0] +
                                "~" +
                                _vm.filterList.date[1]
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "search" },
                    [
                      _c(
                        "el-popover",
                        {
                          ref: "popover",
                          staticClass: "searchPop",
                          attrs: {
                            placement: "top",
                            title: "批量搜索",
                            trigger: "click",
                            width: "378",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "close",
                              on: { click: _vm.closeSearchPanel },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                          _c("base-muti-input", {
                            ref: "baseMutiInput",
                            attrs: { default: _vm.referenceNumber },
                            model: {
                              value: _vm.referenceNumber,
                              callback: function ($$v) {
                                _vm.referenceNumber = $$v
                              },
                              expression: "referenceNumber",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "right",
                                margin: "16px 0 0",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: { click: _vm.handleEmpty },
                                },
                                [_vm._v("清空")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: { click: _vm.handleConfirm },
                                },
                                [_vm._v("确定")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-input", {
                        staticClass: "input-search",
                        attrs: {
                          value: _vm.searchVal,
                          placeholder: "请输入提单号搜索",
                        },
                        on: { focus: _vm.handleFocus },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "btn-search",
                          on: { click: _vm.handleConfirm },
                        },
                        [_c("i", { staticClass: "el-icon-search" })]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "hold", on: { click: _vm.hold } }, [
                    _vm._v("HOLD"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "import", on: { click: _vm.batchExport } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/bill/import.png"),
                          alt: "",
                        },
                      }),
                      _vm._v("导出 "),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "customer-list flex_c_c",
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = true
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/order/setting.png"),
                        },
                      }),
                      _vm._v(" 自定义列表 "),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "filter" },
                    [
                      _c(
                        "base-filter",
                        {
                          ref: "BaseFilter",
                          attrs: { isActive: _vm.isActive, width: 466 },
                          on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "filter" },
                            [
                              _vm.companyDrapdown.length > 0 &&
                              _vm.pickUpOptions.length > 0 &&
                              _vm.originOptions.length > 0 &&
                              _vm.destinationOptions.length > 0 &&
                              _vm.userOptions.length > 0
                                ? _c("SelectFilter", {
                                    ref: "selectFilter",
                                    attrs: {
                                      filterListJson: _vm.filterListJson,
                                      filterListId: _vm.filterListId,
                                      companyDrapdown: _vm.companyDrapdown,
                                      pickUpOptions: _vm.pickUpOptions,
                                      originOptions: _vm.originOptions,
                                      destinationOptions:
                                        _vm.destinationOptions,
                                      userOptions: _vm.userOptions,
                                      tableCode: "MBLSea",
                                    },
                                    on: { ok: _vm.ok1 },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForecast,
                  expression: "showForecast",
                },
              ],
              staticClass: "back",
              on: { click: _vm.back },
            },
            [
              _vm._m(0),
              _c("img", {
                attrs: {
                  src: require("@/assets/images/bill/arrow.png"),
                  alt: "",
                },
              }),
              _c("span", [_vm._v("接受预报")]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForecast,
                  expression: "showForecast",
                },
              ],
              staticClass: "forecast-tip",
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/bill/warning.png") },
              }),
              _vm._v("Ctns总数须与提单件数一致，Gro.W总数须与提单重量一致 "),
            ]
          ),
        ],
        2
      ),
      !_vm.showForecast
        ? [
            _vm.activeName !== "" &&
            _vm.activeName !== 4 &&
            _vm.permissson !== undefined
              ? _c(
                  "div",
                  { staticClass: "tag2" },
                  [
                    _vm._l(
                      _vm.config.billShipSubtabOptions,
                      function (item, index) {
                        return [
                          _vm.activeName == index && index != 4
                            ? _c(
                                "div",
                                { key: index },
                                _vm._l(item, function (keys) {
                                  return _c(
                                    "span",
                                    {
                                      key: keys.value,
                                      staticClass: "tag-item",
                                      class: {
                                        "tag-acitve":
                                          _vm.activeName2 === keys.value,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClick(
                                            "tag2",
                                            keys.value
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          keys.label +
                                            `(${
                                              _vm.tabNumer
                                                ?.find(
                                                  (a) =>
                                                    a.type === +_vm.activeName
                                                )
                                                ?.subTab.find(
                                                  (a) => a.type === keys.value
                                                )?.qty || 0
                                            })`
                                        ) + " "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                        ]
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "data",
        },
        [
          !_vm.showForecast
            ? [
                _vm.height !== 0
                  ? _c("base-table", {
                      attrs: {
                        height: _vm.height,
                        tableData: _vm.tableData,
                        pageIndex: _vm.pagerConfig.pageIndex,
                        pageSize: _vm.pagerConfig.pageSize,
                      },
                      on: {
                        handlePageChange: _vm.handlePageChange,
                        sortChange: _vm.sortChange,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "transportName",
                            fn: function (row) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.showTrack(row.scope, 1)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(row.scope.transportName) +
                                        " "
                                    ),
                                    _c("svg-icon", {
                                      attrs: {
                                        className: "icon",
                                        iconClass: "link",
                                        title: "查看轨迹",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "ETD",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFilete")(row.scope.ETD, 0)
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "ETA",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFilete")(row.scope.ETA, 0)
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "ATD",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFilete")(row.scope.ATD, 0)
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "ATA",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFilete")(row.scope.ATA, 0)
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "dischargeDate",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFilete")(
                                        row.scope.dischargeDate,
                                        0
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "terminalAppointmentDate",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFilete")(
                                        row.scope.terminalAppointmentDate,
                                        0
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "picPickUpDate",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFilete")(
                                        row.scope.picPickUpDate,
                                        0
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "emptyReturnedDate",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFilete")(
                                        row.scope.emptyReturnedDate,
                                        0
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "forecastDate",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFilete")(
                                        row.scope.forecastDate,
                                        0
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "availableDate",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFilete")(
                                        row.scope.availableDate,
                                        0
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "createdOn",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFilete")(
                                        row.scope.createdOn,
                                        1
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "classification",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.scope.classification === 0
                                        ? "直单"
                                        : "分单"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "blNo",
                            fn: function (row) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "infobox" },
                                  [
                                    _vm._v(" " + _vm._s(row.scope.blNo) + " "),
                                    row.scope.pickUpPriorityId &&
                                    _vm.activeName === 1
                                      ? [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content:
                                                  row.scope.pickUpPriority
                                                    .contents,
                                                placement: "right",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "info",
                                                  style: {
                                                    background:
                                                      row.scope.pickUpPriority
                                                        .colorArgb,
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      "margin-top": "2px",
                                                    },
                                                    attrs: {
                                                      src: require("@/assets/images/bill/info.png"),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            },
                          },
                          {
                            key: "isfStatus",
                            fn: function (row) {
                              return [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color: row.scope.isfStatus
                                        ? ""
                                        : "#FE453D",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatterIsfStatus")(row.scope)
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "isUploadMBLFile",
                            fn: function (row) {
                              return [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color: row.scope.isUploadMBLFile
                                        ? "#666666"
                                        : "#FEAA4F",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        row.scope.isUploadMBLFile
                                          ? "已上传"
                                          : "未上传"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "clearenceType",
                            fn: function (row) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "clearenceType",
                                    class: `clearenceType${row.scope.clearenceType}`,
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.config.clearenceTypeOptions.find(
                                          (a) =>
                                            a.value === row.scope.clearenceType
                                        ).label
                                      ) + " "
                                    ),
                                  ]
                                ),
                                row.scope.clearenceSupplier
                                  ? _c("span", { staticClass: "supplier" }, [
                                      _vm._v(
                                        _vm._s(row.scope.clearenceSupplier)
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "pickUpType",
                            fn: function (row) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "pickUpType",
                                    class: `pickUpType${row.scope.pickUpType}`,
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.config.pickUpTypeOptions.find(
                                          (a) =>
                                            a.value === row.scope.pickUpType
                                        ).label
                                      ) + " "
                                    ),
                                  ]
                                ),
                                row.scope.pickUpSupplier
                                  ? _c("span", { staticClass: "supplier" }, [
                                      _vm._v(_vm._s(row.scope.pickUpSupplier)),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "opt",
                            fn: function (row) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "table-opt" },
                                  [
                                    _vm._l(
                                      _vm.config.billShipOptOptions[
                                        _vm.activeName !== ""
                                          ? _vm.activeName + 1
                                          : 0
                                      ],
                                      function (objs) {
                                        return [
                                          objs?.vIf &&
                                          objs?.vIf(
                                            _vm.activeName2,
                                            row.scope.pickUpType,
                                            row.scope.isPushFMS,
                                            row.scope.isOnLoad
                                          )
                                            ? [
                                                !objs.children
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "permissson",
                                                              rawName:
                                                                "v-permissson",
                                                              value: `${objs?.permissson}`,
                                                              expression:
                                                                "`${objs?.permissson}`",
                                                            },
                                                          ],
                                                          key: objs?.permissson,
                                                          class: [
                                                            ...Object.values(
                                                              objs?.class
                                                            ).filter(
                                                              (a) =>
                                                                a !==
                                                                "new-message"
                                                            ),
                                                            row.scope
                                                              .hasNewFollowUp
                                                              ? objs?.class
                                                                  ?.newMessage
                                                              : "",
                                                          ],
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.optClickEvent(
                                                                objs?.event
                                                                  ?.eventName,
                                                                row.scope,
                                                                objs?.event
                                                                  ?.options,
                                                                objs?.permissson
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(objs?.name)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : [
                                                      _c(
                                                        "el-dropdown",
                                                        {
                                                          key: objs?.permissson,
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "el-dropdown-link",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    objs?.name
                                                                  )
                                                              ),
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-caret-bottom",
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-dropdown-menu",
                                                            {
                                                              attrs: {
                                                                slot: "dropdown",
                                                              },
                                                              slot: "dropdown",
                                                            },
                                                            [
                                                              _vm._l(
                                                                objs?.children,
                                                                function (
                                                                  childrenObjs,
                                                                  moreIndex
                                                                ) {
                                                                  return [
                                                                    childrenObjs?.vIf &&
                                                                    childrenObjs?.vIf(
                                                                      _vm.activeName2
                                                                    )
                                                                      ? _c(
                                                                          "el-dropdown-item",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "permissson",
                                                                                  rawName:
                                                                                    "v-permissson",
                                                                                  value: `${childrenObjs?.permissson}`,
                                                                                  expression:
                                                                                    "`${childrenObjs?.permissson}`",
                                                                                },
                                                                              ],
                                                                            key: moreIndex,
                                                                            class:
                                                                              [
                                                                                row
                                                                                  .scope
                                                                                  .hasNewFollowUp
                                                                                  ? objs
                                                                                      ?.class
                                                                                      ?.newMessage
                                                                                  : "",
                                                                              ],
                                                                            nativeOn:
                                                                              {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.optClickEvent(
                                                                                      childrenObjs
                                                                                        ?.event
                                                                                        ?.eventName,
                                                                                      row.scope,
                                                                                      childrenObjs
                                                                                        ?.event
                                                                                        ?.options,
                                                                                      childrenObjs?.permissson
                                                                                    )
                                                                                  },
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                childrenObjs?.name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "el-dropdown-item",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "permissson",
                                                                                  rawName:
                                                                                    "v-permissson",
                                                                                  value: `${childrenObjs?.permissson}`,
                                                                                  expression:
                                                                                    "`${childrenObjs?.permissson}`",
                                                                                },
                                                                              ],
                                                                            key: childrenObjs?.permissson,
                                                                            class:
                                                                              [
                                                                                row
                                                                                  .scope
                                                                                  .hasNewFollowUp
                                                                                  ? objs
                                                                                      ?.class
                                                                                      ?.newMessage
                                                                                  : "",
                                                                              ],
                                                                            nativeOn:
                                                                              {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.optClickEvent(
                                                                                      childrenObjs
                                                                                        ?.event
                                                                                        ?.eventName,
                                                                                      row.scope,
                                                                                      childrenObjs
                                                                                        ?.event
                                                                                        ?.options,
                                                                                      childrenObjs?.permissson
                                                                                    )
                                                                                  },
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                childrenObjs?.name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                  ]
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                              ]
                                            : _vm._e(),
                                          !objs?.vIf
                                            ? [
                                                !objs.children
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "permissson",
                                                              rawName:
                                                                "v-permissson",
                                                              value: `${objs?.permissson}`,
                                                              expression:
                                                                "`${objs?.permissson}`",
                                                            },
                                                          ],
                                                          key: objs?.permissson,
                                                          class: [
                                                            ...Object.values(
                                                              objs?.class
                                                            ).filter(
                                                              (a) =>
                                                                a !==
                                                                "new-message"
                                                            ),
                                                            row.scope
                                                              .hasNewFollowUp
                                                              ? objs?.class
                                                                  ?.newMessage
                                                              : "",
                                                          ],
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.optClickEvent(
                                                                objs?.event
                                                                  ?.eventName,
                                                                row.scope,
                                                                objs?.event
                                                                  ?.options,
                                                                objs?.permissson
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(objs?.name)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : [
                                                      _c(
                                                        "el-dropdown",
                                                        {
                                                          key: objs?.permissson,
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "el-dropdown-link",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    objs.name
                                                                  )
                                                              ),
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-caret-bottom",
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-dropdown-menu",
                                                            {
                                                              attrs: {
                                                                slot: "dropdown",
                                                              },
                                                              slot: "dropdown",
                                                            },
                                                            [
                                                              _vm._l(
                                                                objs?.children,
                                                                function (
                                                                  childrenObjs,
                                                                  moreIndex
                                                                ) {
                                                                  return [
                                                                    childrenObjs?.vIf &&
                                                                    childrenObjs?.vIf(
                                                                      _vm.activeName2
                                                                    )
                                                                      ? _c(
                                                                          "el-dropdown-item",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "permissson",
                                                                                  rawName:
                                                                                    "v-permissson",
                                                                                  value: `${childrenObjs?.permissson}`,
                                                                                  expression:
                                                                                    "`${childrenObjs?.permissson}`",
                                                                                },
                                                                              ],
                                                                            key: moreIndex,
                                                                            class:
                                                                              [
                                                                                row
                                                                                  .scope
                                                                                  .hasNewFollowUp
                                                                                  ? objs
                                                                                      ?.class
                                                                                      ?.newMessage
                                                                                  : "",
                                                                              ],
                                                                            nativeOn:
                                                                              {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.optClickEvent(
                                                                                      childrenObjs
                                                                                        ?.event
                                                                                        ?.eventName,
                                                                                      row.scope,
                                                                                      childrenObjs
                                                                                        ?.event
                                                                                        ?.options,
                                                                                      childrenObjs?.permissson
                                                                                    )
                                                                                  },
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                childrenObjs?.name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "el-dropdown-item",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "permissson",
                                                                                  rawName:
                                                                                    "v-permissson",
                                                                                  value: `${childrenObjs?.permissson}`,
                                                                                  expression:
                                                                                    "`${childrenObjs?.permissson}`",
                                                                                },
                                                                              ],
                                                                            key: childrenObjs?.permissson,
                                                                            class:
                                                                              [
                                                                                row
                                                                                  .scope
                                                                                  .hasNewFollowUp
                                                                                  ? objs
                                                                                      ?.class
                                                                                      ?.newMessage
                                                                                  : "",
                                                                              ],
                                                                            nativeOn:
                                                                              {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.optClickEvent(
                                                                                      childrenObjs
                                                                                        ?.event
                                                                                        ?.eventName,
                                                                                      row.scope,
                                                                                      childrenObjs
                                                                                        ?.event
                                                                                        ?.options,
                                                                                      childrenObjs?.permissson
                                                                                    )
                                                                                  },
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                childrenObjs?.name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                  ]
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                              ]
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        633238022
                      ),
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.showForecast
            ? [
                _c("InvoiceTable", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.invoiceloading,
                      expression: "invoiceloading",
                    },
                  ],
                  attrs: {
                    editData: _vm.forecastData,
                    row: _vm.rowData,
                    mdlType: _vm.rowData.type,
                    height: _vm.height,
                  },
                  on: { cancel: _vm.cancel, save: _vm.save, ok: _vm.ok3 },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("CustomerListDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible,
          useFildList: _vm.useFildListForTab,
          useLogId: _vm.useLogId,
          tabCode: _vm.tabCode,
          tableCode: _vm.tabelCode,
        },
        on: {
          ok: _vm.ok,
          cancel: function ($event) {
            _vm.dialogVisible = false
          },
        },
      }),
      _c("follow-up-records-drawer", {
        attrs: {
          drawer: _vm.drawer,
          rowData: _vm.rowData,
          "bill-type": _vm.rowData.status === 2 ? 1 : 0,
        },
        on: {
          close: function ($event) {
            _vm.drawer = false
          },
        },
      }),
      _c("sure-refuse-bill-dialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible1,
          dialogType: _vm.checkBillType,
          priorityList: _vm.priorityList,
          mblId: _vm.rowData.id,
        },
        on: {
          ok: _vm.ok2,
          cancel: function ($event) {
            _vm.dialogVisible1 = false
          },
        },
      }),
      _c("DetailDrawer", {
        attrs: {
          drawer: _vm.drawer2,
          billType: _vm.billType,
          rowData: _vm.rowData,
        },
        on: {
          close: function ($event) {
            _vm.drawer2 = false
          },
        },
      }),
      _c("UploadAttachmentDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible2,
          diaLogType: 0,
          rowData: _vm.rowData,
        },
        on: {
          ok: _vm.ok4,
          close: function ($event) {
            _vm.dialogVisible2 = false
          },
        },
      }),
      _c("AppointmentDialog", {
        attrs: { dialogVisible: _vm.dialogVisible3, rowData: _vm.rowData },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible3 = false
          },
          ok: _vm.ok2,
        },
      }),
      _c("ArriveWarehouseDialog", {
        attrs: { dialogVisible: _vm.dialogVisible4, rowData: _vm.rowData },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible4 = false
          },
          ok: _vm.ok2,
        },
      }),
      _c("OutWarehouseDialog", {
        attrs: { dialogVisible: _vm.dialogVisible5, rowData: _vm.rowData },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible5 = false
          },
          ok: _vm.ok2,
        },
      }),
      _c("DateTimeDialog", {
        attrs: { dialogVisible: _vm.dialogVisible6, type: _vm.dateTimeType },
        on: {
          ok: _vm.dateTimeOk,
          close: function ($event) {
            _vm.dialogVisible6 = false
          },
        },
      }),
      _c("CheckTrackDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible7,
          type: _vm.trackType,
          rowData: _vm.rowData,
          billType: _vm.billType,
        },
        on: {
          close: function ($event) {
            _vm.dialogVisible7 = false
          },
        },
      }),
      _c("GGTDrawer", {
        attrs: {
          drawer: _vm.drawer3,
          mblId: _vm.rowData.id,
          billNo: _vm.rowData.blNo,
        },
        on: {
          close: function ($event) {
            _vm.drawer3 = false
          },
        },
      }),
      _c("HoldDialog", {
        attrs: { dialogVisible: _vm.dialogVisible8 },
        on: {
          ok: _vm.dateTimeOk,
          cancel: function ($event) {
            _vm.dialogVisible8 = false
          },
        },
      }),
      _c("PushFmsDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible9,
          mblId: _vm.rowData.id,
          pickUpType: _vm.rowData.pickUpType,
          qty: _vm.rowData.qty,
        },
        on: {
          ok: _vm.ok2,
          cancel: function ($event) {
            _vm.dialogVisible9 = false
          },
        },
      }),
      _c("FmsFollowUpListDrawer", {
        attrs: { drawer: _vm.drawer4, rowData: _vm.rowData },
        on: {
          close: function ($event) {
            _vm.drawer4 = false
          },
        },
      }),
      _c("LoadOrderDrawer", {
        attrs: { drawer: _vm.drawer5, rowData: _vm.rowData },
        on: {
          close: function ($event) {
            _vm.drawer5 = false
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "back-blue", staticStyle: { cursor: "pointer" } },
      [
        _c("img", {
          attrs: { src: require("@/assets/images/bill/back.png"), alt: "" },
        }),
        _c("span", [_vm._v("返回提单列表")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./Ship.vue?vue&type=template&id=e045a68c&scoped=true&"
import script from "./Ship.vue?vue&type=script&lang=js&"
export * from "./Ship.vue?vue&type=script&lang=js&"
import style0 from "./Ship.vue?vue&type=style&index=0&id=e045a68c&lang=less&scoped=true&"
import style1 from "./Ship.vue?vue&type=style&index=1&id=e045a68c&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e045a68c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\git\\kjp_oms_be\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e045a68c')) {
      api.createRecord('e045a68c', component.options)
    } else {
      api.reload('e045a68c', component.options)
    }
    module.hot.accept("./Ship.vue?vue&type=template&id=e045a68c&scoped=true&", function () {
      api.rerender('e045a68c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/bill/Ship.vue"
export default component.exports
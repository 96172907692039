<template>
  <div class="view-contanier">
    <div class="header">
      <template v-if="!showForecast">
        <div class="tag">
          <span
            v-for="item in config.billStatusOptions"
            :key="item.value"
            v-permissson="`${item.permissson}`"
            :class="['tag-item', { 'tag-acitve': activeName === item.value }]"
            @click="handleClick('tag1', item.value)"
          >{{
            item.label +
              (item.value !== ""
                ? `(${tabNumer?.find((a) => a.type === +item.value)?.qty || 0})`
                : `(${allTotal})`)
          }}
          </span>
        </div>
        <div class="opt">
          <div v-if="filterList.date" class="checkDate flex_c_c">
            查询时间：{{ filterList.date[0] + "~" + filterList.date[1] }}
          </div>
          <div class="search">
            <el-popover
              ref="popover"
              placement="top"
              class="searchPop"
              title="批量搜索"
              trigger="click"
              width="378"
            >
              <div class="close" @click="closeSearchPanel">
                <i class="el-icon-close"></i>
              </div>
              <base-muti-input
                ref="baseMutiInput"
                v-model="referenceNumber"
                :default="referenceNumber"
              ></base-muti-input>
              <div style="text-align: right; margin: 16px 0 0">
                <el-button size="small" @click="handleEmpty">清空</el-button>
                <el-button
                  size="small"
                  type="primary"
                  @click="handleConfirm"
                >确定</el-button
                >
              </div>
            </el-popover>
            <el-input
              :value="searchVal"
              placeholder="请输入提单号搜索"
              class="input-search"
              @focus="handleFocus"
            ></el-input>
            <div class="btn-search" @click="handleConfirm">
              <i class="el-icon-search"></i>
            </div>
          </div>
          <div class="hold" @click="hold">HOLD</div>
          <div class="import" @click="batchExport">
            <img src="@/assets/images/bill/import.png" alt="" />导出
          </div>
          <div class="customer-list flex_c_c" @click="dialogVisible = true">
            <img src="@/assets/images/order/setting.png" />
            自定义列表
          </div>
          <div class="filter">
            <base-filter
              ref="BaseFilter"
              :isActive="isActive"
              :width="466"
              @sendFilter="sendFilter"
              @reset="reset"
            >
              <div class="filter">
                <SelectFilter
                  v-if="companyDrapdown.length > 0 &&
                    pickUpOptions.length > 0 &&
                    originOptions.length > 0 &&
                    destinationOptions.length > 0 &&
                    userOptions.length > 0
                  "
                  ref="selectFilter"
                  :filterListJson="filterListJson"
                  :filterListId="filterListId"
                  :companyDrapdown="companyDrapdown"
                  :pickUpOptions="pickUpOptions"
                  :originOptions="originOptions"
                  :destinationOptions="destinationOptions"
                  :userOptions="userOptions"
                  tableCode="MBLSea"
                  @ok="ok1"
                ></SelectFilter>
                <!-- <el-form ref="form" :model="filterList" label-width="70px">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="创建日期">
                        <el-date-picker
                          v-model="filterList.date"
                          style="width: 320px"
                          type="daterange"
                          range-separator="至"
                          align="center"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="yyyy-MM-dd"
                          unlink-panels
                          :picker-options="pickerOptions"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="客户">
                        <el-select
                          v-model="filterList.companyId"
                          style="width: 320px"
                          filterable
                          placeholder="请选择客户"
                          multiple
                        >
                          <el-option
                            v-for="item in companyOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row v-if="activeName !== 0">
                    <el-col :span="12">
                      <el-form-item label="清关行">
                        <el-select
                          v-model="filterList.supplierClearenceIds"
                          style="width: 320px"
                          placeholder="请选择"
                          multiple
                        >
                          <el-option
                            v-for="item in clearenceOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="提货车行">
                        <el-select
                          v-model="filterList.pickUpSupplierIds"
                          style="width: 320px"
                          placeholder="请选择"
                          multiple
                        >
                          <el-option
                            v-for="item in pickUpOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="始发港">
                        <el-select
                          v-model="filterList.originHarborCodes"
                          style="width: 320px"
                          placeholder="请选择"
                          multiple
                        >
                          <el-option
                            v-for="item in originOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="目的港">
                        <el-select
                          v-model="filterList.destinationHarborCodes"
                          style="width: 320px"
                          placeholder="请选择"
                          multiple
                        >
                          <el-option
                            v-for="item in destinationOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="提单类型">
                        <el-select
                          v-model="filterList.classification"
                          style="width: 320px"
                          placeholder="提单类型"
                        >
                          <el-option
                            v-for="item in config.billTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="业务员">
                        <el-select
                          v-model="filterList.saleIds"
                          style="width: 320px"
                          filterable
                          placeholder="请选择"
                          multiple
                        >
                          <el-option
                            v-for="item in userOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form> -->
              </div>
            </base-filter>
          </div>
        </div>
      </template>
      <div v-show="showForecast" class="back" @click="back">
        <div class="back-blue" style="cursor: pointer">
          <img src="@/assets/images/bill/back.png" alt="" />
          <span>返回提单列表</span>
        </div>
        <img src="@/assets/images/bill/arrow.png" alt="" />
        <span>接受预报</span>
      </div>
      <div v-show="showForecast" class="forecast-tip">
        <img
          src="@/assets/images/bill/warning.png"
        />Ctns总数须与提单件数一致，Gro.W总数须与提单重量一致
      </div>
    </div>
    <template v-if="!showForecast">
      <div
        v-if="activeName !== '' && activeName !== 4 && permissson !== undefined"
        class="tag2"
      >
        <template v-for="(item, index) in config.billShipSubtabOptions">
          <div v-if="activeName == index && index != 4" :key="index">
            <span
              v-for="keys in item"
              :key="keys.value"
              class="tag-item"
              :class="{ 'tag-acitve': activeName2 === keys.value }"
              @click="handleClick('tag2', keys.value)"
            >{{
              keys.label +
                `(${
                  tabNumer
                    ?.find((a) => a.type === +activeName)
                    ?.subTab.find((a) => a.type === keys.value)?.qty || 0
                })`
            }}
            </span>
          </div>
        </template>
      </div>
    </template>
    <div v-loading="loading" class="data">
      <template v-if="!showForecast">
        <base-table
          v-if="height !== 0"
          :height="height"
          :tableData="tableData"
          :pageIndex="pagerConfig.pageIndex"
          :pageSize="pagerConfig.pageSize"
          @handlePageChange="handlePageChange"
          @sortChange="sortChange"
        >
          <template slot="transportName" slot-scope="row">
            <span class="link" @click="showTrack(row.scope, 1)">
              {{ row.scope.transportName }}
              <svg-icon className="icon" iconClass="link" title="查看轨迹" />
            </span>
          </template>
          <template slot="ETD" slot-scope="row">
            {{ row.scope.ETD | dateFilete(0) }}
          </template>
          <template slot="ETA" slot-scope="row">
            {{ row.scope.ETA | dateFilete(0) }}
          </template>
          <template slot="ATD" slot-scope="row">
            {{ row.scope.ATD | dateFilete(0) }}
          </template>
          <template slot="ATA" slot-scope="row">
            {{ row.scope.ATA | dateFilete(0) }}
          </template>
          <template slot="dischargeDate" slot-scope="row">
            {{ row.scope.dischargeDate | dateFilete(0) }}
          </template>
          <template slot="terminalAppointmentDate" slot-scope="row">
            {{ row.scope.terminalAppointmentDate | dateFilete(0) }}
          </template>
          <template slot="picPickUpDate" slot-scope="row">
            {{ row.scope.picPickUpDate | dateFilete(0) }}
          </template>
          <template slot="emptyReturnedDate" slot-scope="row">
            {{ row.scope.emptyReturnedDate | dateFilete(0) }}
          </template>
          <template slot="forecastDate" slot-scope="row">
            {{ row.scope.forecastDate | dateFilete(0) }}
          </template>
          <template slot="availableDate" slot-scope="row">
            {{ row.scope.availableDate | dateFilete(0) }}
          </template>
          <template slot="createdOn" slot-scope="row">
            {{ row.scope.createdOn | dateFilete(1) }}
          </template>
          <template slot="classification" slot-scope="row">
            {{ row.scope.classification === 0 ? "直单" : "分单" }}
          </template>
          <template slot="blNo" slot-scope="row">
            <div class="infobox">
              {{ row.scope.blNo }}
              <template v-if="row.scope.pickUpPriorityId && activeName === 1">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.scope.pickUpPriority.contents"
                  placement="right"
                >
                  <span
                    class="info"
                    :style="{ background: row.scope.pickUpPriority.colorArgb }"
                  >
                    <img
                      style="margin-top: 2px"
                      src="@/assets/images/bill/info.png"
                    />
                  </span>
                </el-tooltip>
              </template>
            </div>
          </template>
          <template slot="isfStatus" slot-scope="row">
            <!-- <template v-if="row.scope.isfStatus">
            </template> -->
            <span :style="{ color: row.scope.isfStatus ? '' : '#FE453D' }">{{
              row.scope | formatterIsfStatus
            }}</span>
          </template>
          <template slot="isUploadMBLFile" slot-scope="row">
            <span
              :style="{
                color: row.scope.isUploadMBLFile ? '#666666' : '#FEAA4F',
              }"
            >{{ row.scope.isUploadMBLFile ? "已上传" : "未上传" }}</span
            >
          </template>
          <template slot="clearenceType" slot-scope="row">
            <span
              class="clearenceType"
              :class="`clearenceType${row.scope.clearenceType}`"
            >{{
              config.clearenceTypeOptions.find(
                (a) => a.value === row.scope.clearenceType
              ).label
            }}
            </span>
            <span v-if="row.scope.clearenceSupplier" class="supplier">{{
              row.scope.clearenceSupplier
            }}</span>
          </template>
          <template slot="pickUpType" slot-scope="row">
            <span
              class="pickUpType"
              :class="`pickUpType${row.scope.pickUpType}`"
            >{{
              config.pickUpTypeOptions.find(
                (a) => a.value === row.scope.pickUpType
              ).label
            }}
            </span>
            <span v-if="row.scope.pickUpSupplier" class="supplier">{{
              row.scope.pickUpSupplier
            }}</span>
          </template>
          <template slot="opt" slot-scope="row">
            <div class="table-opt">
              <template
                v-for="objs in config.billShipOptOptions[
                  activeName !== '' ? activeName + 1 : 0
                ]"
              >
                <!-- 判断数据里面是否有要判断的数据  没有要判断的数据的一般为基础类型 -->
                <template
                  v-if="
                    objs?.vIf &&
                    objs?.vIf(
                      activeName2,
                      row.scope.pickUpType,
                      row.scope.isPushFMS,
                      row.scope.isOnLoad
                    )
                  "
                >
                  <!-- 判断是否有子选项  有则认定为有更多选项 -->
                  <template v-if="!objs.children">
                    <span
                      :key="objs?.permissson"
                      v-permissson="`${objs?.permissson}`"
                      :class="[
                        ...Object.values(objs?.class).filter(
                          (a) => a !== 'new-message'
                        ),
                        row.scope.hasNewFollowUp ? objs?.class?.newMessage : '',
                      ]"
                      @click="
                        optClickEvent(
                          objs?.event?.eventName,
                          row.scope,
                          objs?.event?.options,
                          objs?.permissson
                        )
                      "
                    >{{ objs?.name }}</span
                    >
                  </template>
                  <!-- 以下为更多选项显示 -->
                  <template v-else>
                    <el-dropdown :key="objs?.permissson">
                      <span class="el-dropdown-link">
                        {{ objs?.name }}<i class="el-icon-caret-bottom"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <template
                          v-for="(childrenObjs, moreIndex) in objs?.children"
                        >
                          <el-dropdown-item
                            v-if="
                              childrenObjs?.vIf &&
                              childrenObjs?.vIf(activeName2)
                            "
                            :key="moreIndex"
                            v-permissson="`${childrenObjs?.permissson}`"
                            :class="[
                              row.scope.hasNewFollowUp
                                ? objs?.class?.newMessage
                                : '',
                            ]"
                            @click.native="
                              optClickEvent(
                                childrenObjs?.event?.eventName,
                                row.scope,
                                childrenObjs?.event?.options,
                                childrenObjs?.permissson
                              )
                            "
                          >{{ childrenObjs?.name }}</el-dropdown-item
                          >
                          <el-dropdown-item
                            v-else
                            :key="childrenObjs?.permissson"
                            v-permissson="`${childrenObjs?.permissson}`"
                            :class="[
                              row.scope.hasNewFollowUp
                                ? objs?.class?.newMessage
                                : '',
                            ]"
                            @click.native="
                              optClickEvent(
                                childrenObjs?.event?.eventName,
                                row.scope,
                                childrenObjs?.event?.options,
                                childrenObjs?.permissson
                              )
                            "
                          >{{ childrenObjs?.name }}</el-dropdown-item
                          >
                        </template>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </template>
                <template v-if="!objs?.vIf">
                  <!-- 判断是否有子选项  有则认定为有更多选项 -->
                  <template v-if="!objs.children">
                    <span
                      :key="objs?.permissson"
                      v-permissson="`${objs?.permissson}`"
                      :class="[
                        ...Object.values(objs?.class).filter(
                          (a) => a !== 'new-message'
                        ),
                        row.scope.hasNewFollowUp ? objs?.class?.newMessage : '',
                      ]"
                      @click="
                        optClickEvent(
                          objs?.event?.eventName,
                          row.scope,
                          objs?.event?.options,
                          objs?.permissson
                        )
                      "
                    >{{ objs?.name }}</span
                    >
                  </template>
                  <!-- 以下为更多选项显示 -->
                  <template v-else>
                    <el-dropdown :key="objs?.permissson">
                      <span class="el-dropdown-link">
                        {{ objs.name }}<i class="el-icon-caret-bottom"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <template
                          v-for="(childrenObjs, moreIndex) in objs?.children"
                        >
                          <el-dropdown-item
                            v-if="
                              childrenObjs?.vIf &&
                              childrenObjs?.vIf(activeName2)
                            "
                            :key="moreIndex"
                            v-permissson="`${childrenObjs?.permissson}`"
                            :class="[
                              row.scope.hasNewFollowUp
                                ? objs?.class?.newMessage
                                : '',
                            ]"
                            @click.native="
                              optClickEvent(
                                childrenObjs?.event?.eventName,
                                row.scope,
                                childrenObjs?.event?.options,
                                childrenObjs?.permissson
                              )
                            "
                          >{{ childrenObjs?.name }}</el-dropdown-item
                          >
                          <el-dropdown-item
                            v-else
                            :key="childrenObjs?.permissson"
                            v-permissson="`${childrenObjs?.permissson}`"
                            :class="[
                              row.scope.hasNewFollowUp
                                ? objs?.class?.newMessage
                                : '',
                            ]"
                            @click.native="
                              optClickEvent(
                                childrenObjs?.event?.eventName,
                                row.scope,
                                childrenObjs?.event?.options,
                                childrenObjs?.permissson
                              )
                            "
                          >{{ childrenObjs?.name }}</el-dropdown-item
                          >
                        </template>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </template>
              </template>
            </div>
          </template>
        </base-table>
      </template>
      <template v-if="showForecast">
        <InvoiceTable
          v-loading="invoiceloading"
          :editData="forecastData"
          :row="rowData"
          :mdlType="rowData.type"
          :height="height"
          @cancel="cancel"
          @save="save"
          @ok="ok3"
        ></InvoiceTable>
      </template>
    </div>
    <CustomerListDialog
      :dialogVisible="dialogVisible"
      :useFildList="useFildListForTab"
      :useLogId="useLogId"
      :tabCode="tabCode"
      :tableCode="tabelCode"
      @ok="ok"
      @cancel="dialogVisible = false"
    ></CustomerListDialog>
    <follow-up-records-drawer
      :drawer="drawer"
      :rowData="rowData"
      :bill-type="rowData.status === 2 ? 1 : 0"
      @close="drawer = false"
    ></follow-up-records-drawer>
    <sure-refuse-bill-dialog
      :dialogVisible="dialogVisible1"
      :dialogType="checkBillType"
      :priorityList="priorityList"
      :mblId="rowData.id"
      @ok="ok2"
      @cancel="dialogVisible1 = false"
    ></sure-refuse-bill-dialog>
    <DetailDrawer
      :drawer="drawer2"
      :billType="billType"
      :rowData="rowData"
      @close="drawer2 = false"
    ></DetailDrawer>
    <UploadAttachmentDialog
      :dialogVisible="dialogVisible2"
      :diaLogType="0"
      :rowData="rowData"
      @ok="ok4"
      @close="dialogVisible2 = false"
    ></UploadAttachmentDialog>
    <AppointmentDialog
      :dialogVisible="dialogVisible3"
      :rowData="rowData"
      @cancel="dialogVisible3 = false"
      @ok="ok2"
    >
    </AppointmentDialog>
    <ArriveWarehouseDialog
      :dialogVisible="dialogVisible4"
      :rowData="rowData"
      @cancel="dialogVisible4 = false"
      @ok="ok2"
    >
    </ArriveWarehouseDialog>
    <OutWarehouseDialog
      :dialogVisible="dialogVisible5"
      :rowData="rowData"
      @cancel="dialogVisible5 = false"
      @ok="ok2"
    >
    </OutWarehouseDialog>
    <DateTimeDialog
      :dialogVisible="dialogVisible6"
      :type="dateTimeType"
      @ok="dateTimeOk"
      @close="dialogVisible6 = false"
    >
    </DateTimeDialog>
    <CheckTrackDialog
      :dialogVisible="dialogVisible7"
      :type="trackType"
      :rowData="rowData"
      :billType="billType"
      @close="dialogVisible7 = false"
    >
    </CheckTrackDialog>
    <GGTDrawer
      :drawer="drawer3"
      :mblId="rowData.id"
      :billNo="rowData.blNo"
      @close="drawer3 = false"
    >
    </GGTDrawer>
    <HoldDialog
      :dialogVisible="dialogVisible8"
      @ok="dateTimeOk"
      @cancel="dialogVisible8 = false"
    >
    </HoldDialog>
    <PushFmsDialog
      :dialogVisible="dialogVisible9"
      :mblId="rowData.id"
      :pickUpType="rowData.pickUpType"
      :qty="rowData.qty"
      @ok="ok2"
      @cancel="dialogVisible9 = false"
    >
    </PushFmsDialog>
    <FmsFollowUpListDrawer
      :drawer="drawer4"
      :rowData="rowData"
      @close="drawer4 = false"
    >
    </FmsFollowUpListDrawer>
    <LoadOrderDrawer
      :drawer="drawer5"
      :rowData="rowData"
      @close="drawer5 = false"
    >
    </LoadOrderDrawer>
  </div>
</template>

<script>
import { permissions } from '@/utils/common'
const BaseFilter = () => import('@/components/filter/BaseFilter')
const SelectFilter = () => import('@/components/filter/SelectFilter')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput')
const InvoiceTable = () => import('@/components/table/InvoiceTable')
const CustomerListDialog = () =>
  import('@/components/dialog/CustomerListDialog.vue')
const BaseTable = () => import('@/components/table/BaseTable.vue')
const FollowUpRecordsDrawer = () =>
  import('@/components/drawer/FollowUpRecordsDrawer')
import config from '@/utils/config'
// import { getRecentMonthDate } from '@/utils/common'
import {
  getMBLPageList,
  getMBLPriorityList,
  getMBLProduct,
  saveMBLProduct,
  forecastAccepted,
  arrivedAndDischarge,
  reservationArrived,
  mblFinished,
  realeaseMBL
} from '@/api/bill'
import { getSysTableUser, getSaleList } from '@/api/system'
import { getTaxRateList, getAddressBookDrapdown } from '@/api/basic'

import SureRefuseBillDialog from './components/SureRefuseBillDialog.vue'
import DetailDrawer from './components/DetailDrawer.vue'
import UploadAttachmentDialog from './components/UploadAttachmentDialog.vue'
import AppointmentDialog from './components/AppointmentDialog.vue'
import ArriveWarehouseDialog from './components/ArriveWarehouseDialog.vue'
import PushFmsDialog from './components/PushFmsDialog.vue'
import OutWarehouseDialog from './components/OutWarehouseDialog.vue'
import DateTimeDialog from './components/DateTimeDialog.vue'
import CheckTrackDialog from './components/CheckTrackDialog.vue'
import GGTDrawer from './components/GGTDrawer.vue'
import HoldDialog from './components/HoldDialog.vue'
import FmsFollowUpListDrawer from './components/FmsFollowUpListDrawer.vue'
import LoadOrderDrawer from './components/LoadOrderDrawer.vue'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import axios from 'axios'
import { myMixins } from '@/mixins'
export default {
  name: 'ShipBill',
  components: {
    BaseFilter,
    BaseMutiInput,
    BaseTable,
    InvoiceTable,
    CustomerListDialog,
    FollowUpRecordsDrawer,
    SureRefuseBillDialog,
    DetailDrawer,
    UploadAttachmentDialog,
    AppointmentDialog,
    ArriveWarehouseDialog,
    OutWarehouseDialog,
    DateTimeDialog,
    CheckTrackDialog,
    GGTDrawer,
    HoldDialog,
    PushFmsDialog,
    FmsFollowUpListDrawer,
    LoadOrderDrawer,
    SelectFilter
  },
  filters: {
    dateFilete: function(date, type) {
      if (date && !type) {
        const newDate = /\d{4}-\d{1,2}-\d{1,2}/g.exec(date)
        return newDate[0]
      } else if (date && type) {
        return date.slice(0, date.length - 3)
      } else {
        return ''
      }
    },
    formatterIsfStatus(row) {
      if (row.isfStatus) {
        return config.isfStatusOptions.find((a) => a.value === row.isfStatus)
          .label
      } else if (row.status === 2 && row.clearenceType === 0) {
        return '已完结'
      } else {
        return '未填报'
      }
    }
  },
  mixins: [myMixins],
  data() {
    return {
      config,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      billType: 0, // 空海运类型 0：海运 1：空运   统一这里调配
      activeName: 0,
      activeName2: 0,
      searchVal: '',
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      referenceNumber: '',
      filterList: {},
      filterListJson: [],
      filterListId: '',
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
      dialogVisible7: false,
      dialogVisible8: false,
      dialogVisible9: false,
      checkBillType: 0,
      tabelCode: 'MBLOversea', // 用户习惯空海运类型
      useLogId: '',
      height: 0,
      tableResData: {
        columns: [],
        total: 0
      },
      useFildList: [],
      loading: false,
      invoiceloading: false,
      isActive: false,
      drawer: false,
      drawer2: false,
      drawer3: false,
      drawer4: false,
      drawer5: false,
      rowData: {},
      sortable: {},
      priorityList: [],
      pickUpOptions: [],
      clearenceOptions: [],
      userOptions: [],
      destinationOptions: [],
      originOptions: [],
      tabNumer: [],
      showForecast: false, // 是否显示预报页面
      forecastData: [], // 预报数据
      dateTimeType: '到港', // 到港和卸船弹窗类型
      trackType: 0 // 查看轨迹类型
    }
  },
  computed: {
    ...mapGetters([
      'billShipTabNumber',
      'addressBookOptions',
      'companyDrapdown'
    ]),
    tabCode() {
      return `${
        this.activeName !== ''
          ? this.config.billStatusOptions.find(
              (a) => a.value === this.activeName
            )?.permissson
          : 'QB'
      }-${this.activeName2}`
    },
    permissson() {
      return this.config.billStatusOptions.find(
        (a) => a.value === this.activeName
      )?.permissson
    },
    useFildListForTab() {
      const arr = this.useFildList.filter(
        (a) =>
          a.selected === true &&
          (a.tabCode === this.tabCode || a.tabCode === null)
      )
      if (arr.length > 0) {
        return arr
      } else {
        // 说明是新加的tab 页面 且 没有用户习惯
        return this.useFildList
      }
    },
    companyOptions() {
      return this.companyDrapdown
    },
    tableData() {
      if (this.tableResData.columns.length === 0) {
        return {}
      }
      const isCustomArr = [
        'transportName',
        'ETA',
        'ETD',
        'ATA',
        'ATD',
        'blNo',
        'isUploadMBLFile',
        'clearenceType',
        'pickUpType',
        'isfStatus',
        'classification',
        'dischargeDate',
        'terminalAppointmentDate',
        'picPickUpDate',
        'emptyReturnedDate',
        'forecastDate',
        'availableDate',
        'createdOn'
      ]
      const isSortArr = [
        'ETA',
        'ETD',
        'ATA',
        'ATD',
        'dischargeDate',
        'terminalAppointmentDate',
        'picPickUpDate',
        'emptyReturnedDate',
        'forecastDate',
        'availableDate',
        'createdOn'
      ]
      const isCopy = ['mblNo', 'blNo', 'containerNumber']
      const isAlign = ['mblNo', 'blNo']
      const optWidth = {
        0: 180,
        1: 280,
        1000: 140,
        2: 200,
        3: 280,
        4: 280,
        5: 240,
        6: 200,
        7: 200,
        8: 240,
        100: 240,
        101: 240,
        102: 240,
        103: 140,
        105: 200,
        106: 180,
        107: 180,
        10000: 140,
        undefined: 180,
        '': 180
      }
      const newArr = this.useFildListForTab.map((item) => {
        return {
          filed: item.filed,
          label: item.label,
          width: item.width,
          isSort: isSortArr.indexOf(item.filed) > -1, // 是否支持过滤
          align: isAlign.indexOf(item.filed) > -1 && 'left',
          fixed: item.isFixed, // 是否固定 true, left, right
          isCustom: isCustomArr.indexOf(item.filed) > -1, // 是否自定义列显示
          isCopy: isCopy.indexOf(item.filed) > -1
        }
      })
      if (parseInt(this.activeName, 10) >= 2 || this.activeName === '') {
        newArr.push({
          filed: 'picArrivedDate',
          label: '到仓时间',
          width: '170',
          formatter: (row) =>
            row.picArrivedDate
              ? row.picArrivedDate.slice(0, row.picArrivedDate.length - 3)
              : ''
        })
        newArr.push({
          filed: 'firstDepartureDate',
          label: '出仓时间',
          width: '170',
          formatter: (row) =>
            row.firstDepartureDate
              ? row.firstDepartureDate.slice(
                  0,
                  row.firstDepartureDate.length - 3
                )
              : ''
        })
      }
      newArr.push({
        filed: 'opt',
        label: '操作',
        width: optWidth[this.activeName2],
        isCustom: true,
        fixed: 'right'
      })
      return {
        fileds: newArr,
        ...this.tableResData,
        mutiCheck: true
      }
    },
    allTotal() {
      return this.tabNumer
        ? this.tabNumer.reduce((prev, next) => {
            return (prev += next.qty)
          }, 0)
        : 0
    }
  },
  watch: {
    '$store.state.connect.connect': {
      handler: function(query) {
        if (query.menuCode === 'HYTD') {
          const json = JSON.parse(query.queryJson)
          this.activeName = json.queryParam.status
          this.activeName2 = json.queryParam.subStatus
          this.searchVal = json.queryParam.mblNo.join(',')
          this.referenceNumber = json.queryParam.mblNo
          console.log('this.referenceNumber', this.referenceNumber)
          // 重置filter
          const param = {
            pageIndex: 1,
            pageSize: 20
          }
          this.pagerConfig = param
          this.filterList = {
            date: '',
            companyId: [],
            supplierClearenceIds: [],
            pickUpSupplierIds: [],
            destinationHarborCodes: [],
            originHarborCodes: [],
            saleIds: [],
            classification: ''
          }
          this.getUserUseLog()
        }
      },
      deep: true,
      immediate: true
    }
  },
  async created() {
    // this.filterList.date = getRecentMonthDate(1)
    this.getUserUseLog()
    this.getPriorityList()
    this.getUserList()
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }

    const pickUpOptions = await this.$store.dispatch(
      'getSupplierDropDownList',
      { scopValue: 'T' }
    )
    this.pickUpOptions = pickUpOptions
    const clearenceOptions = await this.$store.dispatch(
      'getSupplierDropDownList',
      { scopValue: 'Q' }
    )
    this.clearenceOptions = clearenceOptions
    const destinationOptions = await this.$store.dispatch('getHarborDrapDown', {
      portType: 1,
      type: 1
    })
    this.destinationOptions = destinationOptions
    const originOptions = await this.$store.dispatch('getHarborDrapDown', {
      portType: 1,
      type: 0
    })
    this.originOptions = originOptions
    this.getFilter()
  },
  mounted() {
    this.height = document.querySelectorAll('.data')[0].offsetHeight - 42
    if (!this.$store.state.connect.connect.menuCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeList = ['QB', 'DCL', 'QGZ', 'TCJ', 'YC', 'WJ']
      const childrenCode = menu.children.map((a) => a.code)
      const codeIndex = codeList.findIndex((a) => childrenCode.includes(a))
      this.activeName = codeIndex ? codeIndex - 1 : ''
      // 挂载后要给子Tab赋值  防止按钮权限导致的activeName2默认值还是'0'
      const activeName2List = ['0', '2', '9']
      this.activeName2 =
        this.activeName !== ''
          ? +activeName2List.find((a, i) => i === +this.activeName)
          : undefined
    }
  },
  beforeDestroy() {
    this.$store.dispatch('setEmptyConnect')
  },
  methods: {
    handleClick(type, value) {
      let flag = false
      if (type === 'tag1') {
        if (this.activeName !== value) {
          flag = true
        }
        this.activeName = value
        this.activeName2 =
          this.activeName !== ''
            ? this.config.billShipSubtabOptions[this.activeName][0].value
            : undefined
        this.tabelHeight()
      } else {
        if (this.activeName2 !== value) {
          flag = true
        }
        this.activeName2 = value
      }
      const param = {
        pageIndex: 1,
        pageSize: this.pagerConfig.pageSize
      }
      this.selectionItem = []
      this.pagerConfig = param
      flag && this.getUserUseLog()
      // this.$refs.baseMutiInput.reset()
      // this.referenceNumber = ''
      // this.searchVal = ''
    },
    tabelHeight() {
      if (this.activeName === '' || this.activeName === 4) {
        this.height = document.querySelector('.data').offsetHeight + 6
      } else if (this.activeName === 1 && this.showForecast) {
        this.height = document.querySelector('.data').offsetHeight - 160
      } else {
        this.height = document.querySelector('.data').offsetHeight - 42
      }
    },
    handleFocus() {
      this.$refs.popover.doShow()
    },
    closeSearchPanel() {
      this.$refs.popover.doClose()
    },
    handleConfirm() {
      if (this.referenceNumber && this.referenceNumber !== '') {
        this.searchVal = this.referenceNumber.join(';')
        this.$refs.popover.doClose()
      }
      // todo 重置filter
      this.filterList = {}
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = param
      this.isActive = false
      this.getList(param, {
        status: this.activeName !== '' ? this.activeName : undefined,
        subStatus: this.activeName2 !== '' ? this.activeName2 : undefined
      })
      // this.getList(this.pagerConfig)
    },
    handleEmpty() {
      this.referenceNumber = ''
      this.searchVal = ''
      this.$refs.baseMutiInput.reset()
      this.$refs.popover.doClose()
      this.initList()
    },
    async getPriorityList() {
      try {
        const res = await getMBLPriorityList({
          pageIndex: 1,
          pageSize: 1000
        })
        if (res.success) {
          this.priorityList = res.data.list
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error
      }
    },
    getUserList() {
      getSaleList().then((res) => {
        if (res.success) {
          const arr = []
          res.data.map((item) => {
            arr.push({
              value: item.id,
              label: item.nameCH
            })
          })
          this.userOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    getList(params, filter) {
      this.loading = true
      this.$nextTick(async() => {
        try {
          // const realFilter = filter || this.formatterFilter()
          params = {
            ...params,
            queryParam: {
              ...this.sortable,
              // ...realFilter,
              type: this.billType,
                      status: this.activeName !== '' ? this.activeName : undefined,
        subStatus: this.activeName2 !== '' ? this.activeName2 : undefined,
              ...filter,
              ...this.filterList,
        mblNo: this.searchVal ? this.searchVal.split(';') : undefined
            }
          }
          const res = await getMBLPageList(params)
          if (res.success) {
            !res.data.list && (res.data.list = [])
            const newList1 = res.data.list.map((a) => {
              return {
                ...a,
                ATD: a.atd,
                ATA: a.ata,
                ETD: a.etd,
                ETA: a.eta
              }
            })
            const newList2 = newList1.filter((a) => {
              return (
                a.status === parseInt(this.activeName, 10) &&
                a.subStatus === parseInt(this.activeName2, 10)
              )
            })
            const newList = this.activeName !== '' ? newList2 : newList1
            this.tableResData.columns = newList
            this.tabNumer = res.data.tabNumer
            this.tableResData.total = res.data.tabNumer
              ? this.activeName !== ''
                ? res.data.tabNumer[this.activeName]?.subTab.find(
                    (a) => a.type === parseInt(this.activeName2, 10)
                  )?.qty || 0
                : res.data.tabTotal
              : 0
            this.tabelHeight()
            this.loading = false
          } else {
            this.$message.error(res.errorMessage)
          }
          // console.log(res)
        } catch (error) {
          this.loading = false
          console.error(error)
        }
      })
    },
    async getUserUseLog() {
      try {
        this.loading = true
        // this.tableResData.columns = []
        const res = await getSysTableUser({
          tableCode: this.tabelCode,
          tabCode: this.tabCode
        })
        if (res.success) {
          if (res.data && res.data.value) {
            await this.getList(this.pagerConfig)
            const filedArr = JSON.parse(res.data.value)
            this.useLogId = res.data.id
            this.useFildList = filedArr
          }
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    sendFilter() {
      const filterList = this.$refs.selectFilter.getData()
      if (Object.keys(filterList).length > 0) {
        this.isActive = true
      }
      this.filterList = filterList
      // this.initList()
      const param = {
        pageIndex: 1,
        pageSize: this.pagerConfig.pageSize
      }
      this.referenceNumber = ''
      this.searchVal = ''
      this.$refs.baseMutiInput.reset()
      this.$refs.popover.doClose()
      this.pagerConfig = param
      this.getList(param)
    },
    reset() {
      this.$refs.selectFilter.reset()
      this.isActive = false
      this.referenceNumber = ''
      this.searchVal = ''
      this.$refs.baseMutiInput.reset()
      this.$refs.popover.doClose()
    },
    // sendFilter() {
    //   if (
    //     (this.filterList.companyId && this.filterList.companyId.length > 0) ||
    //     this.filterList.supplierClearenceIds.length > 0 ||
    //     this.filterList.pickUpSupplierIds.length > 0 ||
    //     this.filterList.destinationHarborCodes.length > 0 ||
    //     this.filterList.originHarborCodes.length > 0 ||
    //     this.filterList.saleIds.length > 0 ||
    //     this.filterList.classification !== '' ||
    //     this.filterList.date.length !== 0
    //   ) {
    //     this.isActive = true
    //   } else {
    //     this.isActive = false
    //   }
    //   const param = {
    //     pageIndex: 1,
    //     pageSize: 20
    //   }
    //   // 清空提单搜索
    //   this.pagerConfig = param
    //   this.referenceNumber = ''
    //   this.searchVal = ''
    //   this.$refs.baseMutiInput.reset()
    //   this.$refs.popover.doClose()
    //   this.getList(param, {
    //     status: this.activeName !== '' ? this.activeName : undefined,
    //     subStatus: this.activeName2 !== '' ? this.activeName2 : undefined,
    //     startTime: this.filterList.date ? this.filterList.date[0] : undefined,
    //     endTime: this.filterList.date ? this.filterList.date[1] : undefined,
    //     companyId:
    //       this.filterList.companyId && this.filterList.companyId.length > 0
    //         ? this.filterList.companyId
    //         : undefined,
    //     supplierClearenceIds:
    //       this.filterList.supplierClearenceIds &&
    //       this.filterList.supplierClearenceIds.length > 0
    //         ? this.filterList.supplierClearenceIds
    //         : undefined,
    //     pickUpSupplierIds:
    //       this.filterList.pickUpSupplierIds &&
    //       this.filterList.pickUpSupplierIds.length > 0
    //         ? this.filterList.pickUpSupplierIds
    //         : undefined,
    //     destinationHarborCodes:
    //       this.filterList.destinationHarborCodes &&
    //       this.filterList.destinationHarborCodes.length > 0
    //         ? this.filterList.destinationHarborCodes
    //         : undefined,
    //     originHarborCodes:
    //       this.filterList.originHarborCodes &&
    //       this.filterList.originHarborCodes.length > 0
    //         ? this.filterList.originHarborCodes
    //         : undefined,
    //     saleIds:
    //       this.filterList.saleIds && this.filterList.saleIds.length > 0
    //         ? this.filterList.saleIds
    //         : undefined,
    //     classification: this.filterList.classification
    //       ? this.filterList.classification
    //       : undefined
    //   })
    // },
    // reset() {
    //   this.filterList = {
    //     date: getRecentMonthDate(1),
    //     companyId: [],
    //     supplierClearenceIds: [],
    //     pickUpSupplierIds: [],
    //     destinationHarborCodes: [],
    //     originHarborCodes: [],
    //     saleIds: [],
    //     classification: ''
    //   }
    //   this.$refs.baseMutiInput.reset()
    //   this.referenceNumber = ''
    //   this.searchVal = ''
    //   this.isActive = false
    // },
    handlePageChange(pager) {
      this.pagerConfig = pager
      this.getList(pager)
    },
    sortChange(orderObj) {
      this.sortable = {
        sortField: orderObj.prop,
        sortBy: orderObj.order === 'asc' ? 0 : 1
      }
      this.getList(this.pagerConfig)
    },
    formatterFilter() {
      return {
        status: this.activeName !== '' ? this.activeName : undefined,
        subStatus: this.activeName2 !== '' ? this.activeName2 : undefined,
        mblNo: this.searchVal ? this.searchVal.split(';') : undefined,
        startTime: this.filterList.date ? this.filterList.date[0] : undefined,
        endTime: this.filterList.date ? this.filterList.date[1] : undefined,
        type: this.billType,
        companyId:
          this.filterList.companyId && this.filterList.companyId.length > 0
            ? this.filterList.companyId
            : undefined,
        supplierClearenceIds:
          this.filterList.supplierClearenceIds &&
          this.filterList.supplierClearenceIds.length > 0
            ? this.filterList.supplierClearenceIds
            : undefined,
        pickUpSupplierIds:
          this.filterList.pickUpSupplierIds &&
          this.filterList.pickUpSupplierIds.length > 0
            ? this.filterList.pickUpSupplierIds
            : undefined,
        destinationHarborCodes:
          this.filterList.destinationHarborCodes &&
          this.filterList.destinationHarborCodes.length > 0
            ? this.filterList.destinationHarborCodes
            : undefined,
        originHarborCodes:
          this.filterList.originHarborCodes &&
          this.filterList.originHarborCodes.length > 0
            ? this.filterList.originHarborCodes
            : undefined,
        saleIds:
          this.filterList.saleIds && this.filterList.saleIds.length > 0
            ? this.filterList.saleIds
            : undefined,
        classification:
          this.filterList.classification !== ''
            ? this.filterList.classification
            : undefined
      }
    },
    initList() {
      // this.reset()
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
    },
    detail(row) {
      this.rowData = row
      this.drawer2 = true
    },
    async getFilter() {
      try {
        const res = await getSysTableUser({
          tableCode: 'MBLSea',
          type: 1
        })
        if (res.success) {
          this.filterListJson = JSON.parse(res.data.value)
          this.filterListId = res.data.id
          console.log('filterListJson', this.filterListJson)
        }
      } catch (error) {
        console.warn(error)
      }
    },
    ok1() {
      this.getFilter()
    },
    ok() {
      this.dialogVisible = false
      this.getUserUseLog()
    },
    ok2() {
      this.dialogVisible1 = false
      this.dialogVisible3 = false
      this.dialogVisible4 = false
      this.dialogVisible5 = false
      this.dialogVisible9 = false
      this.getList(this.pagerConfig)
    },
    async ok3({ items }) {
      this.invoiceloading = true
      const consigneeName = this.rowData.consigneeName
      const shipperName = this.rowData.shipperName
      // 获取当前客户的地址数据
      const addressBookResult = await getAddressBookDrapdown({
        type: -1,
        companyId: this.rowData.companyId
      })
      const addressDataList = addressBookResult.data
      // 获取shipper和consignee的地址数据
      const shipper = addressDataList
        .filter((a) => a.type === 20)
        .find((a) => a.companyName === shipperName)
      const consingee = addressDataList
        .filter((a) => a.type === 20)
        .find((a) => a.companyName === consigneeName)
      const params = {
        items,
        shipper: {
          ...shipper,
          addressLine: [
            shipper.addressLine1 ? shipper.addressLine1 : '',
            shipper.addressLine2 ? shipper.addressLine2 : ''
          ]
        },
        consingee: {
          ...consingee,
          addressLine: [
            consingee.addressLine1 ? shipper.addressLine1 : '',
            consingee.addressLine2 ? consingee.addressLine2 : ''
          ]
        },
        mblId: this.rowData.id
      }
      try {
        const result = await forecastAccepted(params)
        if (result.success) {
          this.initList()
          this.showForecast = false
          this.$store.commit('set_empty_item')
          this.$message.success(result.errorMessage)
          this.invoiceloading = false
        } else {
          this.$message.error(result.errorMessage)
          this.invoiceloading = false
        }
      } catch (error) {
        console.error(error)
      }
    },
    ok4() {
      this.initList()
    },
    updateLog(row) {
      row.hasNewFollowUp = false
      this.rowData = row
      this.drawer = true
    },
    checkBill(row, type) {
      this.rowData = row
      this.checkBillType = type
      this.dialogVisible1 = true
    },
    uploadAttachment(row) {
      this.rowData = row
      this.dialogVisible2 = true
    },
    async audit(row) {
      this.loading = true
      try {
        const result = await getMBLProduct({ mblId: row.id })
        if (result.success) {
          this.forecastData = await this.getTaxRateList(result.data)
          this.loading = false
          this.rowData = row
          this.showForecast = true
          this.height = document.querySelector('.data').offsetHeight - 160
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    ggt(row) {
      this.rowData = row
      this.drawer3 = true
    },
    // 获取基础税率和加征税率
    async getTaxRateList(val) {
      try {
        const hsCodeArr = val.map((a) => a.hscode).join(',')
        const result = await getTaxRateList({ hsCode: hsCodeArr })
        if (result.success) {
          const newValArr = val.map((a) => {
            const myHsCode = result.data.find((b) => b.hsCode === a.hscode)
            myHsCode && (a.baseRate = myHsCode.baseRate)
            myHsCode && (a.addRate = myHsCode.addRate)
            return {
              ...a,
              hsCode: a.hscode,
              used: a.usedFor
            }
          })
          return Promise.resolve(newValArr)
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancel(type) {
      if (type) {
        this.getList(this.pagerConfig)
      }
      this.showForecast = false
      this.tabelHeight()
    },
    back() {
      this.invoiceloading = false
      this.showForecast = false
      this.tabelHeight()
    },
    async save({ items }) {
      const params = {
        items,
        mblId: this.rowData.id,
        isStaged: true
      }
      try {
        const result = await saveMBLProduct(params)
        if (result.success) {
          this.$message.success('暂存成功')
          this.$store.commit('set_empty_item')
          this.showForecast = false
          this.getList(this.pagerConfig)
        } else {
          this.$message.error(result.errorMessage)
        }
        console.log(result)
      } catch (error) {
        console.error(error)
      }
    },
    optClickEvent(event, row, options) {
      this[event](row, ...options)
      // this.checkCode(code)
    },
    // async checkCode(code) {
    //   if (
    //     this.$store.state.connect.connect.btnCode &&
    //     code === this.$store.state.connect.connect.btnCode
    //   ) {
    //     this.$store.dispatch('readConnnet')
    //   }
    // },
    showDateTime(row, type) {
      this.dateTimeType = type
      this.rowData = row
      this.dialogVisible6 = true
    },
    showTrack(row, type) {
      this.trackType = type
      this.rowData = row
      this.dialogVisible7 = true
    },
    async dateTimeOk(val) {
      this.dialogVisible6 = false
      const params = {
        mblId: this.rowData.id,
        date: val,
        subStatus: this.dateTimeType === '到港' ? 101 : 102
      }
      try {
        const result = await arrivedAndDischarge(params)
        if (result.success) {
          this.getList(this.pagerConfig)
          this.$message.success(result.errorMessage)
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    // 预约
    showAppointment(row) {
      this.rowData = row
      this.dialogVisible3 = true
    },
    // 到达
    arrive(row) {
      this.$confirm('是否确认到达', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        iconClass: 'warning',
        showClose: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        center: true
      })
        .then(async() => {
          try {
            const result = await reservationArrived({ mblId: row.id })
            if (result.success) {
              this.$message.success('到达成功')
              this.getList(this.pagerConfig)
            } else {
              this.$message.error(result.errorMessage)
            }
          } catch (error) {
            console.error(error)
          }
        })
        .catch(() => {})
    },
    // 到仓出仓
    async showWarehouse(row, type) {
      this.rowData = row
      type === '0' && (this.dialogVisible4 = true)
      type === '1' && (this.dialogVisible5 = true)
    },
    // 推送fms
    fms(row) {
      this.rowData = row
      this.dialogVisible9 = true
    },
    // fms跟进记录
    fmsRemark(row) {
      this.rowData = row
      this.drawer4 = true
    },
    // 完结
    async over(row) {
      try {
        const result = await mblFinished({ mblId: row.id })
        if (result.success) {
          this.getList(this.pagerConfig)
          this.$message.success(result.errorMessage)
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async batchExport() {
      const filter = this.formatterFilter()
      const date1 = dayjs(filter.endTime)
      if (date1.diff(filter.startTime, 'months') > 6) {
        this.$message.warning('最多下载6个月数据')
        return
      }
      try {
        const res = await axios({
          url: `/backEnd/api/${this.config.supplyCode}/MBL/exportMBLList`,
          method: 'post',
          responseType: 'blob',
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(filter)
        })
        const fileName = window
          .decodeURI(res.headers['content-disposition'].split('=')[2], 'UTF-8')
          .replace(`UTF-8''`, '')
        const filestream = res.data // 返回的文件流
        const blob = new Blob([filestream], {
          type: 'application/vnd.ms-excel'
        })
        const a = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载连接
        a.href = href
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a) // 下载完移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      } catch (error) {
        console.log(error)
      }
    },
    hold() {
      this.dialogVisible8 = true
    },
    async release(row) {
      try {
        const res = await realeaseMBL({ mblId: row.id })
        if (res.success) {
          this.$message.success('release成功')
          this.getList(this.pagerConfig)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    // 装载
    loadOrder(row) {
      this.rowData = row
      this.drawer5 = true
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tag-item {
    width: auto;
    height: 50px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 50px;
    margin-left: 30px;
    cursor: pointer;
    & + .tag-item {
      margin-left: 40px;
    }
  }
  .tag-acitve {
    color: #2395f3;
  }
  .tag2 {
    width: 100%;
    height: 48px;
    background: #fbfbfb;
    border-radius: 2px 2px 0px 0px;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    &:deep(.el-tabs__header) {
      margin: 15px 0;
    }
    &:deep(.el-tabs--card > .el-tabs__header) {
      border-bottom: 0;
    }
    &:deep(.el-tabs__nav) {
      border: 0;
    }
    &:deep(.el-tabs__item) {
      height: 24px;
      line-height: 24px;
      border: 0;
    }
  }
  .back {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    span {
      margin: 0 10px;
    }
    .back-blue {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
    }
  }
  .forecast-tip {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #fe453d;
  }
  .opt {
    display: flex;
    & div {
      border-radius: 4px;
    }
    .search {
      position: relative;
      display: flex;
      width: 200px;
      margin-right: 5px;
      .searchPop {
        /deep/ .el-popover {
          z-index: 9999;
          right: 0;
        }
        .close {
          position: absolute;
          top: 6px;
          right: 10px;
          cursor: pointer;
          font-size: 20px;
        }
      }
      .input-search {
        width: 166px;
        height: 30px !important;
        /deep/ .el-input__inner {
          height: 30px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .btn-search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33px;
        height: 30px;
        border: 1px solid #dbe4f2;
        border-left: 0;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
        box-sizing: border-box;
      }
    }
    .hold {
      width: 80px;
      height: 30px;
      cursor: pointer;
      text-align: center;
      line-height: 30px;
      color: #fe453d;
      border: 1px solid #fe453d;
      border-radius: 2px;
      margin-right: 5px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      box-sizing: border-box;
    }
    .import {
      width: 82px;
      height: 30px;
      background: #2395f3;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      margin-right: 5px;
      box-sizing: border-box;
    }
    .customer-list {
      width: 120px;
      height: 30px;
      border: 1px solid #dbe4f2;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
      box-sizing: border-box;
    }
    .filter {
      height: 30px;
      margin-left: 5px;
    }
  }
  .data {
    width: 100%;
    height: calc(100% - 100px);
  }
  .clearenceType {
    display: inline-block;
    padding: 4px 12px;
    width: 36px;
    height: 16px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    line-height: 16px;
    border-radius: 4px;
  }
  .clearenceType0 {
    color: #35ba83;
    background: rgba(53, 186, 131, 0.1);
  }
  .clearenceType1 {
    color: #9d67fe;
    background: rgba(157, 103, 254, 0.1);
  }
  .pickUpType {
    display: inline-block;
    padding: 4px 12px;
    width: 36px;
    height: 16px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    line-height: 16px;
  }
  .pickUpType0 {
    color: #2395f3;
    background: rgba(35, 149, 243, 0.1);
  }
  .pickUpType1 {
    color: #ff7444;
    background: rgba(255, 116, 68, 0.1);
  }
  .pickUpType2 {
    color: #feaa4f;
    background: rgba(254, 170, 79, 0.1);
  }
  .supplier {
    margin-left: 8px;
    color: #7c8db5;
  }
  .link,
  .link2 {
    width: 28px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 20px;
    cursor: pointer;
  }
  .link + .link {
    margin-left: 15px;
  }
  .link2 {
    margin-right: 15px;
  }
  .refuse {
    color: #999999;
  }
  .new-message {
    position: relative;
    &::after {
      content: " ";
      width: 8px;
      height: 8px;
      background: #fe453d;
      opacity: 1;
      position: absolute;
      border-radius: 50%;
    }
  }
  .infobox {
    display: flex;
    align-items: center;
  }
  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 4px 4px 4px 4px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
.new-message {
  position: relative;
  &::after {
    content: " ";
    width: 8px;
    height: 8px;
    background: #fe453d;
    opacity: 1;
    position: absolute;
    border-radius: 50%;
  }
}
</style>

<template>
  <el-dialog
    v-drag
    title="预约"
    :visible.sync="dialogVisible"
    width="800px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <el-row style="width: 100%">
          <el-col :span="12">
            <div class="form-item">
              <label class="lable"><span class="red">* </span>是否成功</label>
              <el-form-item prop="isSuccess">
                <el-switch
                  v-model="form.isSuccess"
                  :active-text="form.isSuccess ? '成功' : '失败'"
                >
                </el-switch>
              </el-form-item>
            </div>
          </el-col>
          <el-col v-if="form.isSuccess" :span="12">
            <div class="form-item">
              <label
                class="lable"
              ><span class="red">* </span>预约提货时间</label
              >
              <el-form-item prop="picExpactPickUpDate">
                <el-date-picker
                  v-model="form.picExpactPickUpDate"
                  style="width: 320px"
                  type="datetime"
                  placeholder="请选择时间"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </el-col>
          <!-- <el-col :span="12">
            <div class="form-item">
              <label class="lable"><span class="red">* </span>预约时间</label>
              <el-form-item prop="picExpactDate">
                <el-date-picker
                  v-model="form.picExpactDate"
                  style="width: 320px"
                  type="datetime"
                  placeholder="请选择时间"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </el-col> -->
        </el-row>
        <el-row v-if="!form.isSuccess" style="width: 100%">
          <el-col :span="24">
            <div class="form-item">
              <label class="lable"><span class="red">* </span>预约失败原因</label>
              <el-form-item prop="reservationFailReason">
                <el-select
                  v-model="form.reservationFailReason"
                  style="width: 660px;"
                  placeholder="请选择原因"
                >
                  <el-option label="其他原因" value="other"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="form.reservationFailReason === 'other'" prop="reservationFailReason">
                <el-input
                  v-model="form.other"
                  style="width: 660px;"
                  placeholder="请输入其他原因"
                >
                </el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row style="width: 100%">
          <el-col v-if="rowData.clearenceType !== 0 && showAN" :span="12">
            <div class="form-item">
              <label class="lable">AN文件上传</label>
              <el-form-item prop="fileList1">
                <div class="update-demo">
                  <el-upload
                    class="upload-demo"
                    :action="`/backEnd/api/${config.supplyCode}/File/uploadMBLFile?source=1&id=${rowData.id}`"
                    :file-list="form.fileList1"
                    :show-file-list="false"
                    :limit="1"
                    :on-progress="(e) => {
                      onProgress(e, 0)
                    }"
                    :on-success="(e, file, fileList) => {
                      handleSuccess(e, file, fileList, 0 )
                    }"
                  >
                    <el-button
                      class="btn"
                    >
                      <img src="@/assets/images/bill/load.png">选择文件上传
                    </el-button
                    >
                  </el-upload>
                </div>
                <div :style="{'visibility':form.fileList1[0] && form.fileList1[0].fileName ? 'visible' : 'hidden'}" class="fileList">
                  <div style="flex: 1; display: flex; align-items: center">
                    <div class="progress">
                      <el-progress
                        v-if="showAN"
                        type="circle"
                        :width="40"
                        :height="40"
                        :stroke-width="4"
                        :percentage="porgress1[0]"
                      ></el-progress>
                      <img v-if="!porgress1[0]" src="@/assets/images/bill/file.png" />
                    </div>
                    <div class="file">
                      <p class="fileName">{{ form.fileList1[0]?.fileName }}</p>
                      <p v-if="showAN" class="fileSize">{{ form.fileList1[0]?.fileSize }} mb</p>
                    </div>
                  </div>
                  <div class="opt">
                    <i v-if="showAN" class="el-icon-delete icon" title="删除" @click="deleteFile('0')" />
                  </div>
                </div>
              </el-form-item>
            </div>
          </el-col>
          <el-col v-if="rowData.clearenceType !== 0 && showDO" :span="12">
            <div class="form-item">
              <label class="lable"><span class="red">* </span>DO文件上传</label>
              <el-form-item prop="fileList2">
                <div class="update-demo">
                  <el-upload
                    class="upload-demo"
                    :action="`/backEnd/api/${config.supplyCode}/File/uploadMBLFile?source=2&id=${rowData.id}`"
                    :file-list="form.fileList2"
                    :show-file-list="false"
                    :limit="1"
                    :on-progress="(e) => {
                      onProgress(e, 1)
                    }"
                    :on-success="(e, file, fileList) => {
                      handleSuccess(e, file, fileList, 1 )
                    }"
                  >
                    <el-button
                      class="btn"
                    >
                      <img src="@/assets/images/bill/load.png">选择文件上传
                    </el-button
                    >
                  </el-upload>
                </div>
                <div :style="{'visibility':form.fileList2[0] && form.fileList2[0].fileName ? 'visible' : 'hidden'}" class="fileList">
                  <div style="flex: 1; display: flex; align-items: center">
                    <div class="progress">
                      <el-progress
                        v-if="showDO"
                        type="circle"
                        :width="40"
                        :height="40"
                        :stroke-width="4"
                        :percentage="porgress2[0]"
                      ></el-progress>
                      <img v-if="!porgress2[0]" src="@/assets/images/bill/file.png" />
                    </div>
                    <div class="file">
                      <p class="fileName">{{ form.fileList2[0]?.fileName }}</p>
                      <p v-if="showDO" class="fileSize">{{ form.fileList2[0]?.fileSize }} mb</p>
                    </div>
                  </div>
                  <div class="opt">
                    <i v-if="showDO" class="el-icon-delete icon" title="删除" @click="deleteFile('0')" />
                  </div>
                </div>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
      <div v-loading="loading" class="ok flex_c_c" style="margin-left: 20px" @click="notice">
        通知客户
        <el-tooltip
          class="item"
          effect="dark"
          :content="rowData.clearenceType !== 0 ? '提示通知客户上传AN，DO文件' : '提示通知客户上传DO文件'"
          placement="top-start"
        >
          <svg-icon className="icon" iconClass="question"></svg-icon>
        </el-tooltip>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { notifyCompanyUploadFile, reservation } from '@/api/bill'
import { getMBLFile } from '@/api/system'
import config from '@/utils/config'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      config,
      form: {
        isSuccess: true,
        // picExpactDate: '',
        picExpactPickUpDate: '',
        reservationFailReason: '',
        other: '',
        supplierId: '',
        fileList1: [],
        fileList2: []
      },
      rules: {
        isSuccess: [{ required: true, trigger: 'change' }],
        // picExpactDate: [
        //   { required: true, message: '请选择预约时间', trigger: 'blur' }
        // ],
        picExpactPickUpDate: [
          { required: true, message: '请选择预约提货时间', trigger: 'blur' }
        ],
        other: [
          { required: true, message: '请输入其他原因', trigger: 'blur' }
        ],
        supplierId: [
          { required: true, message: '请选择预约失败原因', trigger: 'change' }
        ],
        reservationFailReason: [
          { required: true, message: '请选择提货车行', trigger: 'change' }
        ],
        fileList1: [
          { required: true, message: '请上传AN文件', trigger: 'change' }
        ],
        fileList2: [
          { required: true, message: '请上传DO文件', trigger: 'change' }
        ]
      },
      showAN: true,
      showDO: true,
      porgress1: [],
      porgress2: [],
      loading: false
      // options: []
    }
  },
  watch: {
    async dialogVisible(val) {
      if (val) {
        console.log(this.rowData)
        this.getFileList()
        // try {
        //   const result = await getSupplierDropDownList({ scopValue: 'T' })
        //   if (result.success) {
        //     this.options = result.data
        //   } else {
        //     this.$message.error(result.errorMessage)
        //   }
        // } catch (error) {
        //   console.error(error)
        // }
      }
    }
  },
  methods: {
    async getFileList() {
      try {
        const result = await getMBLFile({ id: this.rowData.id })
        if (result.success) {
          if (result.data.find(a => a.type === 1)) {
            this.form.fileList1 = [result.data.find(a => a.type === 1)]
            this.showAN = false
          }
          if (result.data.find(a => a.type === 2)) {
            this.form.fileList2 = [result.data.find(a => a.type === 2)]
            this.showDO = false
          }
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancel() {
      this.form.fileList1 = []
      this.porgress1 = []
      this.form.fileList2 = []
      this.porgress2 = []
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            mblId: this.rowData.id,
            picExpactPickUpDate: this.form.isSuccess ? this.form.picExpactPickUpDate : undefined,
            reservationFailReason: this.form.isSuccess ? undefined : (this.form.reservationFailReason === 'other' ? this.form.other : this.form.reservationFailReason)
          }
          reservation(params).then((res) => {
            if (res.success) {
              this.$message.success('预约成功')
              this.form.fileList1 = []
              this.porgress1 = []
              this.form.fileList2 = []
              this.porgress2 = []
              this.$emit('ok')
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        }
      })
    },
    async notice() {
      this.loading = true
      try {
        const result = await notifyCompanyUploadFile({ mblId: this.rowData.id })
        if (result.success) {
          this.$message.success(result.errorMessage)
          this.loading = false
        } else {
          this.$message.error(result.errorMessage)
          this.loading = false
        }
      } catch (error) {
        console.error(error)
      }
    },
    onProgress(e, index) {
      switch (index) {
        case 0:
        if (this.porgress1.length === 0) {
          this.porgress1.push([])
        }
        this.porgress1.splice(0, 1, e.percent)
        break
        case 1:
        if (this.porgress2.length === 0) {
          this.porgress2.push([])
        }
        this.porgress2.splice(0, 1, e.percent)
        break
      }
    },
    handleSuccess(e, file, fileList, index) {
      if (e.success) {
        switch (index) {
          case 0:
          if (this.form.fileList1.length === 0) {
            this.form.fileList1.push([])
          }
          this.form.fileList1.splice(0, this.form.fileList1[0] ? 1 : 0, {
            fileId: e.data.fileId,
            fileName: file.name,
            fileSize: Math.ceil(file.size / 1024)
          })
          break
          case 1:
          if (this.form.fileList2.length === 0) {
            this.form.fileList2.push([])
          }
          this.form.fileList2.splice(0, this.form.fileList2[0] ? 1 : 0, {
            fileId: e.data.fileId,
            fileName: file.name,
            fileSize: Math.ceil(file.size / 1024)
          })
          break
        }
      } else {
        this.$message.error(e.errorMessage)
      }
    },
    deleteFile(val) {
      if (val === '0') {
        this.form.fileList1 = []
        this.porgress1 = []
      } else {
        this.form.fileList2 = []
        this.porgress2 = []
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  margin: 0 auto;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .form-item {
    width: 100%;
    position: relative;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
  }
}

.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 20px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.update-demo {
  width: 320px;
  height: 100px;
  background: #f7f7f7;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fileList {
  width: 296px;
  padding: 12px 10px;
  height: 44px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-top: 10px;
  display: flex;
  align-items: center;
  .file {
    margin-left: 10px;
  }
  .fileName {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }
  .fileSize {
    height: 24px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
  }
  .icon {
    font-size: 24px;
    color: #cdcdcd;
    cursor: pointer;
  }
  .opt {
    display: flex;
    align-items: center;
  }
}
.btn {
  width: 160px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #2395f3;
  color: #2395f3;
  background: transparent;
  padding: 0;
  box-sizing: border-box;
  /deep/ span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    img {
      margin-right: 4px;
    }
  }
}
.icon {
  font-size: 18px;
}
</style>
